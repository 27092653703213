import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { StyledAlignContent } from '../AlignContent';
import { M75MediaTextProps } from './M75MediaText';

type StyledM75ContentProps = {
    mediaAlignment: M75MediaTextProps['mediaHorizontalAlignment'];
};

export const StyledM75 = styled.div({
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
});

export const StyledGridArea = styled.div<{ area: 'content' | 'media' }>(({ area }) => ({
    gridArea: area,
}));

export const StyledM75Content = styled.div<StyledM75ContentProps>(
    ({ mediaAlignment, theme }) => ({
        height: 'auto',
        boxSizing: 'border-box',
        width: '100%',
        margin: '0 auto',
        [breakpoints.sm]: {
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateColumns: '1fr 1fr',
            gap: theme.gridConfig.gap,
        },
        [breakpoints.md]: {
            gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.md}, 1fr)`,
        },
        [`${StyledAlignContent}`]: {
            height: '100%',
            borderTop: theme.traits.line.asBorderStyle,
            padding: `${theme.spaces['5']} ${theme.gridConfig.gutter}`,

            [breakpoints.sm]: {
                padding: `${theme.spaces['5']} 0`,
                marginLeft:
                    mediaAlignment === 'right' ? 0 : `calc(6.25vw - ${theme.gridConfig.gap})`,
                marginRight:
                    mediaAlignment === 'right' ? `calc(6.25vw - ${theme.gridConfig.gap})` : 0,
            },
        },
    }),

    switchProp(
        'mediaAlignment',
        {
            left: () => ({
                gridTemplateAreas: "'media content'",
                [breakpoints.md]: {
                    gridTemplateAreas: `". ${'media '.repeat(5)} ${'content '.repeat(5)} ."`,
                },
            }),
            right: () => ({
                gridTemplateAreas: "'content media'",
                [breakpoints.md]: {
                    gridTemplateAreas: `". ${'content '.repeat(5)} ${'media '.repeat(5)} ."`,
                },
            }),
        },
        () => ({
            gridTemplateAreas: "'media content'",
            [breakpoints.md]: {
                gridTemplateAreas: `". ${'media '.repeat(5)} ${'content '.repeat(5)} ."`,
            },
        })
    ),

    // Ensures content aligns with the edge
    // if there is no background color
    ifProp({ backgroundColor: 'none' }, ({ mediaAlignment }) => ({
        [`${StyledAlignContent}`]: {
            paddingLeft: mediaAlignment === 'left' ? undefined : 0,
            paddingRight: mediaAlignment === 'right' ? undefined : 0,
        },
    }))
);

export const StyledImageWrapper = styled.div<{
    w: number;
    h: number;
}>(({ w, h }) => ({
    minWidth: '100%',
    maxHeight: '85vh',
    height: 'auto',
    aspectRatio: `${w} / ${h}`,
    ['> *']: {
        // Fixes an issue on safari where aspect-ratio does not respect max-height
        maxHeight: '85vh',
    },
}));
