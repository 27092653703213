import { Image, Video } from '$shared/components';
import React from 'react';
import { M75MediaAndTextModule } from '~/lib/data-contract';
import { queriesIsolated } from '~/theme/breakpoints';
import { AlignContent } from '../AlignContent';
import { ModuleContainer } from '../ModuleContainer';
import { SpotTextBlock } from '../TextBlock';
import { StyledGridArea, StyledImageWrapper, StyledM75, StyledM75Content } from './styled';
import { useCookieConsent } from '$shared/hooks';

export type M75MediaTextProps = M75MediaAndTextModule;

export const M75MediaText = ({
    image,
    video,
    headline,
    subHeadline,
    subHeadlineUppercase,
    text,
    callToAction,
    mediaHorizontalAlignment = 'left',
    horizontalAlignment = 'left',
    verticalAlignment = 'top',
    ...rest
}: M75MediaTextProps) => {
    const { marketing, statistic } = useCookieConsent();

    const mediaType = video ? 'video' : 'image';
    const imageSize = {
        w: 740,
        h: 995,
    };

    return (
        <ModuleContainer hasGutter="sm" {...rest}>
            <StyledM75>
                <StyledM75Content mediaAlignment={mediaHorizontalAlignment}>
                    <StyledGridArea area="content">
                        <AlignContent alignment={horizontalAlignment} position={verticalAlignment}>
                            <SpotTextBlock
                                headline={headline}
                                subheadline={subHeadline}
                                subHeadlineUppercase={subHeadlineUppercase}
                                html={text}
                                callToAction={callToAction}
                                buttonVariant="tertiary"
                                onAppearAnimation="none"
                            />
                        </AlignContent>
                    </StyledGridArea>
                    <StyledGridArea area="media">
                        {(mediaType === 'image' ||
                            (mediaType === 'video' && !(marketing && statistic))) &&
                        image?.src ? (
                            <StyledImageWrapper {...imageSize}>
                                <Image
                                    {...image}
                                    src={image.src}
                                    cW={imageSize.w}
                                    cH={imageSize.h}
                                    layout="fill"
                                    objectFit="cover"
                                    sizes={`${queriesIsolated.xs} 100vw, ${queriesIsolated.sm} 50vw, 40vw`}
                                />
                            </StyledImageWrapper>
                        ) : null}
                        {mediaType === 'video' &&
                        video?.src &&
                        ((marketing && statistic) || (!(marketing && statistic) && !image?.src)) ? (
                            <Video
                                controls={false}
                                src={video.src}
                                loop={video.loop}
                                playing={video.autoPlay}
                                posterSrc={image?.src}
                                showCustomButtons={video.controls}
                            />
                        ) : null}
                    </StyledGridArea>
                </StyledM75Content>
            </StyledM75>
        </ModuleContainer>
    );
};
